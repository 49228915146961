.filesPage {


    .AccordionCont .accordion-body {
        padding: 0;
    }

    .docsCont {
        padding: 40px 20px 0;

    }

    .fileItem {

        width: 100%;
        text-align: center;
        margin: 0 0 40px 0;

        h3 {
            font-family: "fb_bold","helvetica","arial" !important;
            font-size: 15px;
            margin: 0;

            img {
                margin: 0 0 0 5px;
            }
        }

        p {
            font-size: 15px;
            margin: 0;
            min-height: 22.5px;
        }

        .blueQuad {
            
            background: center center no-repeat transparent;
            width: 156px;
            height: 130px;
            margin: 10px auto 0;

            &.fullBorder {
                background: none;
                border: 1px solid #1991D0;
                border-radius: 10px;
            }

            &.docExist {

                padding: 20px 0 0 0;
                img {
                    width: 17px;
                }
            }

            &.externalLink {

                padding: 30px 0 0 0;

                .linkP {
                    font-size: 15px;
                    margin: 0 0 18px 0;
                }

                .blueBtn {
                    border: 1px solid #A9C0DE;
                    background: #E7EFFB;
                    border-radius: 5px;
                    font-size: 16px;
                    margin: 5px 0 0 0;
                    padding: 3px 20px;

                    &:hover {
                        background: #0f6998;
                        color: white;
                    }
                }

            }

            .readAndSignBtn {

                background: none;
                border: none;
                padding: 0;

                width: 30px;

                &:hover {
                    width: 35px;
                }


                img {
                    width: 100%;
                }
            }
            
            

            .docNoExist {

                padding: 20px 0 0 0;
                img {
                    width: 17px;
                }

                .blueBtn {
                    border: 1px solid #A9C0DE;
                    background: #E7EFFB;
                    border-radius: 5px;
                    font-size: 16px;
                    margin: 5px 0 0 0;
                    padding: 3px 20px;

                    &:hover {
                        background: #0f6998;
                        color: white;
                    }
                }

            }

            
        }

        

        .picturePreview {
            margin: 10px 0 0 0;

            .btnCont {

                margin: 10px 0 0 0;

                img {
                    cursor: pointer;

                    &:hover {
                        width: 28px;
                    }

                }

                .greenBtn {
                    border: 1px solid #A9DEB7;
                    background: #E7FBF2;
                    border-radius: 5px;
                    font-size: 16px;
                    margin: 5px 5px 0 0;
                    padding: 3px 5px;
                    width: 100%;
    
                    &:hover {
                        background: #0f6998;
                        color: white;
                    }
                }

            }
            
        }

    }

}

.filesModal {



    .Message {
        text-align: center;

        img {
            margin: 0 0 20px 0;
        }

        h2 {
            font-size: 24px;
            padding: 0 5%;
            font-family: "fb_bold","helvetica","arial" !important;
        }

    }

    figure {

        text-align: center;

    }
    
    &.thanksFile {
        h2 {
            padding: 0 5%;
        }
    }

    .errorDates {
        color: red;
        margin: 0;
        text-align: center;

        &.warningDates {
            color: black;
            font-weight: bold;
        }

        &.okDates {
            color: green;

            span {
                display: block;
                color: black;
                font-size: 16px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .blueBtn {
        border: 1px solid #A9C0DE;
        background: #E7EFFB;
        border-radius: 5px;
        font-size: 20px;
        text-align: center;
        display: block;
        margin: 5px auto 0;
        padding: 5px 20px;
        cursor: pointer;

        &.disabled {
            cursor: default;
            opacity: .5;
            background: #E7EFFB !important;
            color: black !important;
        }

        &:hover {
            background: #0f6998;
            color: white;
        }
    }

    .btnCont {
        margin: 20px 0 0 0;
    }


    .textReadAndSign {
        overflow-y: auto;
        height: 300px;
        font-size: 16px;
        padding: 5px 20px;
    }

    .formCont {

        background: #EEEEEE;
        width: 100%;
        margin: 20px 0 0 0;
        padding: 0 0 20px 0;

        .form-control {
            background: #EEEEEE;
        }

        .blueSignBtn {

            width: 100%;
            border: 1px solid #A9C0DE;
            background: #1991D0;
            color: white;
            border-radius: 5px;
            font-size: 17px;
            text-align: center;
            margin: 0px 0 0 0;
            padding: 15px 5px;
            cursor: pointer;

            &:hover {
                background: #0f6998;
                color: white;
            }

        }
    }

    &.SMSCodeModal {

        h2 {
            margin: 0 0 20px 0;
            color: #0f6998;
        }

        .blueBtn {
            width: 100%;
            padding: 0 5px;
            margin: 20px 0 0 0;

        }

    }

    &.ishurMahalaModal {

        .inputs {
            padding: 0 5%;
            h4 {
                font-size: 16px;
                color: #B5B5B5;
            }
        }

    }

    &.modalHtml.modalSite .modal-dialog {
        margin-top: 10px;
        margin-bottom: 10px;  
    }

    .showHtml {

        padding: 10px 20px;

        //דוח תקבולים
        table {
            tfoot {
                tr {
                    td {
                        &::before {
                            content: "";
                            position: relative;
                            padding: 10px 0;
                            display: block;
                            border-bottom: 1px solid black;
                            margin: 0 0 10px 0;
                        }
                        position: relative !important;
                        border: none  !important;
                        
    
                    }
                }
            }
        }
    }

}
