
$greenColor :#1BD591;
$bgGreen: #CEF2E5;

$redColor:#E44A4A;
$bgRed:#FFDADE;

$yellowColor: #FFC107;

$blueColor: #1F5081;
$blueLight: #2C67DF;
$greyFont: #535353;
$greyBorder: #B6C1CF;
$greyBorderLighter: #d4dfed;

$buttonBorder: #C8CFE2;



$boxShadow: -1px 1px 5px 2px rgb(51 88 113 / 16%);

$fontBold: "fb_bold","helvetica","arial";


.Desktop .clockInOutCont .ReportsPage,
.Desktop .clockInOutCont .StartStopPage
{

    max-width: 480px;
    margin: 20px auto;
    position: relative;
    

}

.Desktop .clockInOutCont .ReportsPage {

    .confirmation {
        width: 416px;
    }

}

.Mobile .clockInOutCont .AccordionCont {
    
    margin-top: 0px;
    padding-bottom: 50px;
   
}

.clockInOutCont {

    header {
        
        margin-top: -8px;

        h1 {
            margin-top: 0 !important;
        }

        position:  relative;

        .backBtn {
            position: absolute;
            left: 10px;
            top: 10px;
            border: none;
            padding: 0;
            background: transparent;
        }

    }

    .selectCont {
        
        position: relative;
        clear: both;
        width: 310px;
        margin: 0 auto;
        
        img {
            position: absolute;
            top: 9px;
            right: 15px;
        }
    }

    .textAreaCont {

        width: 310px;
        margin: 15px auto 0;

        .textAreaReport {

            border-radius: 20px;
            box-shadow: -1px 0px 5px 2px rgb(51 88 113 / 16%);
            padding: 10px 20px;
    
            font-size: 15px;

        }

    }



   .playStopBtn {
        width: 320px;
        position: relative;
        margin: 0px auto 0;

        opacity: 1;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;

        &.disabled {
            opacity: .6;
        }

        

        .buttonCont {

            width: 320px;
            height: 320px;
            border-radius: 50%;
            margin: 0 auto;
            background: white;
            padding: 20px;


            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            overflow: hidden;

            .clock {
                color: $blueColor;
                text-align: center;
                

                div.clockNumbers {
                    font-size: 45px;
                    font-family: "fb_bold","helvetica","arial" !important;                
                }

                div.showInTime {

                    h3 {
                        font-size: 20px;
                        margin: 0;
                    }
                    

                    span {
                        letter-spacing: -1px;
                        font-size: 23px;
                        font-family: "fb_bold","helvetica","arial" !important;                
                        color: $greenColor
                    }
                    
                }

                p {
                    border-top: 1px solid #BBBBBB;
                    padding-top: 10px;
                    font-size: 20px;
                }
                
            }

            .CircularProgressbar {
                position: absolute;
                width: 300px;
                height: 300px;
            }

           
        }

        .lastExit {
            p {
                margin: 0;
            }
            strong {
                color: $redColor;
                font-family: $fontBold;
                font-size: 25px;
            }
        }

    
    }


    .buttonsDown {

        text-align: center;
        margin: 15px 0 ;
        position: relative;
        z-index: 10;

        button {
            box-shadow: -1px 0px 5px 2px rgb(51 88 113 / 16%);
            padding: 5px 50px;
            border-radius: 5px;
            margin: 0 5px;
            border: 1px solid;

            font-family: "fb_bold","helvetica","arial" !important;

            &.start {
                background: $greenColor;
                border-color: $greenColor;
                color: white;

                &.disabled {
                    background: grey;
                    border-color: grey;
                    opacity: .6;
                }

            }

            &.stop {
                background: white;
                border-color: $redColor;
                color: $redColor;
            }

        }

        &.isIn {
             
            button {

                &.start {

                    background: white;
                    border-color: $greenColor;
                    color: $greenColor;
                }

                &.stop {
                    background: $redColor;
                    border-color: $redColor;
                    color: white;
                }

            }
            


        }

    }

    .openReportPage {
        
        margin: 0 auto 20px;
        display: block;

        box-shadow: -1px 0px 5px 2px rgb(51 88 113 / 16%);
        padding: 5px 50px;
        border-radius: 5px;
        border: 1px solid #E4EBF5;
        background: white;
        width: 310px;

        color: $blueColor;

        //font-family: "fb_bold","helvetica","arial" !important;
        img {
            vertical-align: middle;
            margin: 0 0 0 10px;
        }

        span {
            vertical-align: middle;
        }

        
    }

}

.chooseReportSelect {

    background: url('../../img/icons/clockPage/arrowDown.svg') white 20px center no-repeat;
    border-radius: 20px;
    box-shadow: -1px 0px 5px 2px rgb(51 88 113 / 16%);
    padding: 10px 50px 10px 20px !important;
    
    font-size: 15px;

}

.ReportsPage {

    padding: 0 3%;

    .headerReportsPage {

        display: flex;
        justify-content: center;
        gap: 25px;
        margin: 10px 0 0 0;
        border-bottom: 1px solid #B6C1CF;
        padding: 0 0 20px 0;

        button {

            color: black;
            border: none;
            background: transparent;
            padding: 0;

            span {
                vertical-align: middle;
            }

            img {
                vertical-align: middle;
                margin: 0 0 0 5px;
            }

        }

    }

    nav.monthsNav {

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin: 10px 0 0 0;
        padding: 10px 0 20px;

        font-size: 16px;

        p {
            margin: 0;
            font-weight: bold;
        }

        button {

            color: black;
            border: 1px solid #344655;
            background: transparent;
            padding: 10px 20px 13px;
            border-radius: 20px;
            line-height: 1;

            span {
                vertical-align: middle;
            }

            img {
                vertical-align: middle;
            }

            &.disabled {
                opacity: .4;
            }

        }

    }

    .monthConfirm {

        .showStartStopMonth {

            width: 70%;
            margin: 0 auto 15px;
            letter-spacing: -1px;
    
            display: flex;
            justify-content: space-between;
    
    
        }

        .showStatus {

            background: #fdfdfd;
            margin: 0 -3% 20px;
            padding: 20px 0;
            
            h3 {
                font-size: 20px;
                text-align: center;
            }

            ul.statusCont {
                width: 95%;
                margin: 0 auto;
                display: flex;
                justify-content:space-around;

                li {
                    display: inline-block;
                    border-left: 1px solid $buttonBorder;
                    width: 33%;

                    &:last-child {
                        border: none;
                    }

                    > div {
                        margin: 0;
                        line-height: 1.3;
                        text-align: center;
                        font-size: 16px;

                        span {
                            vertical-align: middle;
                        }

                        img {
                            margin: 0 5px 0 0;
                            vertical-align: middle;
                        }
                       
                    }
                }

            }

        }


    }
    

    .cardsReportCont {

        .CardDayReportItem {
            
            margin: 0 0 20px 0;

            /* edit and show css */
            .headerContReport {

                display: flex;
                width: 100%;
                justify-content: space-between;

                p {
                    margin: 0;
                    font-size: 17px;
                    color: $greyFont;
                    text-align: right;

                    strong {
                        font-family: $fontBold;
                    }
                }

                .leftRight {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 10px 0 0;
                    border-right: 1px solid $greyBorder;

                    span {
                        color: $greyFont;
                        padding: 0 0 0 10px;
                    }
                }

            }
            
            .changeValuesBtn {

                width: 100%;
                background: white;
                border: none;
                border-radius: 10px;
                box-shadow: $boxShadow;
                padding: 1rem 1.25rem;

                &.green {
                    border-right: 10px solid $greenColor;
                }

                &.red {
                    
                    border-right: 10px solid $redColor;
                }

                &.noOpen {
                    border-right: 10px solid $greyBorder;
                }

                &.isAbsence {
                    border-right: 10px solid $yellowColor;
                }
                

                .leftRight {
                    text-align: center;
                    padding: 0;

                    span {
                        padding: 0 1.25rem 0 0;
                        font-size: 17px;
                    }
                }
            }

            .accordion-item {

                border-radius: 10px;
                box-shadow: $boxShadow;

                .accordion-header {

                    &.noOpen {
                        
                        button::after,
                        .leftRight {
                            display: none;
                        }

                    }

                    &.green {

                        button {
                            border-right: 10px solid $greenColor;
                        }
                    }

                    &.red {
                        
                        button {
                            border-right: 10px solid $redColor;
                        }
                    }

                    &.noOpen {
                        button {
                            border-right: 10px solid $greyBorder;
                        }
                    }

                    &.isAbsence {
                        button {
                            border-right: 10px solid $yellowColor;
                        }
                    }

                    button.accordion-button {

                        &:not(.colapsed) {
                            background: white;
                            box-shadow: none;
                        }

                        border-radius: 10px;
                        

                    }


                }

                .accordion-body {


                    padding: 10px 0 0 0;

                    ul.inOutItems {

                        width: 100%;
                        display: flex;
                        justify-content: center;
                        gap: 30px;
                        text-align: center;
                        border-bottom: 1px solid $greyBorderLighter;

                        margin: 0px 0 15px 0;
                        background: #fbfbfb;

                        &:last-child {
                            border: none;
                        }

                        li {
                            
                            color: $greyFont;
                            padding: 0 0 10px 0;

                            span {
                                font-size: 15px;

                                &.error {
                                    color: $redColor;
                                }
                            }

                            
                            
                            p {
                                margin: 0;
                                font-size: 28px;

                                &.error {
                                    color: $redColor;
                                }
                            }
                        }

                    }


                }
            }
            
            
            

        }

    }

    &.editModePage {
        padding-bottom: 70px;
    }

    .confirmation {
        padding: 10px 0px;
        background: rgb(255 255 255 / 90%);
        position: fixed;
        bottom: 20px;
        width: 100%;

        text-align: center;

        button {
            color: white;
            font-family: $fontBold;
            background: $greenColor;
            border: none;
            padding: 5px 10px;
            border-radius: 7px;

        }
    }

    .Summary {

        padding: 10px 20px 20px;

        h2 {
            text-align: center;
            color: $blueColor;
            font-family: $fontBold;
            margin: 0 0 25px 0;
        }

        button.backBtn {

            box-shadow: -1px 0px 5px 2px rgb(51 88 113 / 16%);
            padding: 5px 50px;
            border-radius: 5px;
            margin: 30px auto 0;
            display: block;
            border: 1px solid;

            font-family: $fontBold;

            background: $greenColor;
            border-color: $greenColor;
            color: white;


        }
        
        .openCLoseSumm {

            margin: 10px auto 0;
            max-width: 640px;
            
            h2 {
                margin: 0;
            }

           
            button.accordion-button {

                &:not(.colapsed) {
                    box-shadow: none;
                }
                
                padding: 10px 20px;
                background: white;
                color: black !important;
                border: none !important;

                
                &::after {
                    position: absolute;
                    left: 20px;
                    top: 9px;
                }

            }

            .accordion-body {

                padding: 5px 10px !important;
                
                strong {
                    /* font-family: $fontBold; */
                    font-weight: bold;
                    color: $blueColor;
                }

                h3 {
                    font-size: 18px;
                    margin: 0 0px 10px 0;
                    font-family: $fontBold;
                    text-align: center;

                }

                

                p {
                    font-size: 16px;
                    text-align: center;

                    strong {
                        font-family: $fontBold;
                    }
                }

              
            }
        }
    }

}

.OpenCLoseTextAreaReport {


    margin: 10px auto 0;

    max-width: 640px;

    .accordion-item {
        border-radius: 0px !important;
        border: none !important;
    }



    button.accordion-button {

        &:not(.colapsed) {
            box-shadow: none;
        }
        
        padding: 5px 30px;
        background: #f5f6f9;
        color: black !important;
        border-radius: 0px!important;
        border: none !important;

        
        &::after {
            display: none;
            position: absolute;
            left: 20px;
            top: 5px;
        }

    }

    .accordion-body {

        padding: 5px 30px !important;
        
        strong {
            /* font-family: $fontBold; */
            font-weight: bold;
            color: $blueColor;
        }

        h3 {
            font-size: 18px;
            margin: 0;
        }

        p {
            font-size: 16px;
        }

        .textAreaReport {
            width: 100%;
        }


    }

    &.isEdit {

        .selectCont {
        
            position: relative;
            clear: both;
            width: 310px;
            margin: 0 auto;
            
            img {
                position: absolute;
                top: 9px;
                right: 15px;
            }
        }
    
        .textAreaCont {
    
            width: 310px;
            margin: 15px auto 0;
    
            .textAreaReport {
    
                border-radius: 20px;
                box-shadow: -1px 0px 5px 2px rgb(51 88 113 / 16%);
                padding: 10px 20px;
        
                font-size: 15px;
    
            }
    
        }

        .accordion-body {

            padding: 5px 0px !important;

            .editCont {
                padding: 10px 0 0 0;
                h3 {
                    margin: 0 0 5px 0;
                }
            }

        }
            
    }

}


.reportDownModal {

    .MuiPaper-root {
        border-radius: 7px 7px 0 0;
    }

    .boxDownModal {

        //padding: 10px 5%;
        img.closeDown {
            margin: 10px 5% 0 0;
        }

        .editMyDay {

            margin: 0px 0 0 0;
            min-height: 300px;
            padding: 0 0 70px 0;

            .saveChangesBtns {

                padding: 10px 0px;
                background: rgb(255 255 255 / 90%);
                position: fixed;

                bottom: 15px;
                width: 100%;
                text-align: center;

                display: flex;
                justify-content: center;
                gap: 10px;
        
                
        
                button {
                    
                    font-family: $fontBold;
                    border: 1px solid;
                    padding: 5px 15px;
                    border-radius: 5px;

                    &.saveBtn {
                        border-color: $greenColor;
                        background: $greenColor;
                        color: white;
                    }

                    &.cancelBtn {
                        border-color: black;
                        background: white;
                        color: black;
                    }
        
                }
            }


            .daysitemsCont {

                .isAbsence {
                    margin-top: -10px;
                    background: #fff2cc;
                    padding: 20px 0 30px;

                    h3 {
                        text-align: center;
                        font-size: 20px;
                        color: black;
                        
                    }

                    button.cancelAbs {

                        background: none;
                        border: none;
                        display: block;
                        margin: 10px auto 0;
                        font-size: 16px;
                        color: red;
                        
                        span {
                            vertical-align: middle;
                        }

                        img {
                            vertical-align: middle;
                            margin: 0px 0 0 5px;
                        }


                    }

                    .selectCont {
                        position: relative;
                        clear: both;
                        width: 310px;
                        margin: 0 auto;

                        select {
                            padding-right: 20px !important;
                        }

                        img {
                            position: absolute;
                            top: 9px;
                            right: 15px;
                        }
                    }
                }

                .editHoursItem {

                    padding: 5px 0 20px;

                    &:nth-child(even) {

                        background: $greyBorderLighter;

                        .changeHour {

                            background: $greyBorderLighter;

                        }

                    }

                }
                

            }

            .headerOpenDown {

                h2 {
                    color: $blueLight;
                    text-align: center;
                    font-family: $fontBold;
                    font-size: 30px;
                    margin: 0 0 0px 0;
                }

                p {
                    text-align: center;
                    font-size: 15px;
                }

            }

            .reportEditCount {

                text-align: center;
                margin: 0 0 20px 0;
                font-size: 16px;

                strong {

                    font-family: $fontBold;
                    
                    span {
                        color: $redColor;
                    }
                }

                img {
                    margin: 0 0px 0 5px;
                }
                
            }

            .buttonsEditReportDay {

                display: flex;
                justify-content: center;
                gap: 10px;
                margin: 0 0 10px 0;
                border-bottom: 1px solid $greyBorderLighter;
                padding: 0 0 20px 0;

                button {

                    background: transparent;
                    border: 1px solid $buttonBorder;
                    border-radius: 20px;
                    padding: 5px 5px 5px;
                    width: 160px;
                    text-align: right;
                    color: black;

                    img {
                        width: 31px;
                        height: 31px;
                        margin: 0 0 0 5px;
                    }

                    &:last-child {

                        img {
                            display: inline-block;
                            margin-top: -3px;
                            width: 26px;
                            height: 26px;
                        }

                    }

                }

            }


            ul.inOutItems {

                width: 100%;
                display: flex;
                justify-content: center;
                gap: 30px;
                text-align: center;
                border-bottom: 1px solid $greyBorderLighter;

                margin: 10px 0 0px 0;
                

                &:last-child {
                    border: none;
                }

                li {
                    
                    color: $greyFont;
                    padding: 10px 0;


                    &.in {
                        span {
                            border-color: $greenColor;
                            background: $bgGreen;
                        }
                    }

                    &.out {
                        span {
                            border-color: $redColor;
                            background: $bgRed;
                        }
                    }

                    &.separate {
                        font-size: 30px;
                        vertical-align: top;
                        line-height: 1;
                        color: #8790A1;
                    }

                    span {

                        font-size: 18px;
                        border: 1px solid;
                        border-radius: 10px;
                        padding: 5px 15px;
                        margin: 0 0 10px 0;
                        display: inline-block;

                        /* &.error {
                            color: $redColor;
                        } */
                    }

                    
                    
                    p {
                        margin: 0;
                        font-size: 28px;

                        &.error {
                            color: $redColor;
                        }
                    }
                }

            }


            .changeHour {
                padding: 0;
                margin: 10px auto 0;
                width: 100%;
                background: white;
                color: black;
                border: 1px solid grey;
                padding: 3px;
                border-radius: 5px;
                font-size: 18px;
                display: block;
                color: $greyFont;
            }

            .ShowInfo {

                padding: 10px 20px 20px;

                h2 {
                    text-align: center;
                    color: $blueColor;
                    font-family: $fontBold;
                    margin: 0 0 25px 0;
                }

                p {
                    font-size: 18px;
                    line-height: 1.1;
                }

                button {

                    box-shadow: -1px 0px 5px 2px rgb(51 88 113 / 16%);
                    padding: 5px 50px;
                    border-radius: 5px;
                    margin: 30px auto 0;
                    display: block;
                    border: 1px solid;
        
                    font-family: "fb_bold","helvetica","arial" !important;
        
                    background: $greenColor;
                    border-color: $greenColor;
                    color: white;

        
                }
                

            }


        }

        
    }

}

