$boxShadow: -1px 1px 5px 2px rgb(51 88 113 / 16%);
$blueColor: #1F5081;
$blueLight: #2C67DF;
$fontBold: "fb_bold","helvetica","arial";

$blueLogo: #017ac8;

.trainingPageIndex {

    .traningPage {

        max-width: 480px;
        margin: 20px auto;
        position: relative;

        header {
            
            margin-top: -28px;
            position: relative;

            h1 {
                margin-top: 0 !important;
            }
        }

        ul.list {

            max-width: 480px;
            margin: 20px auto;

            padding: 0 5%;

            li {


                background: white;
                border-radius: 15px;
                box-shadow: $boxShadow;

                //border: 1px solid $blueLight;
                margin: 0 0 30px 0;
                padding: 25px 0px 0;

                .text {
                
                    line-height: 1;
                    padding: 0 0 0 8px;
                    text-align: center;

                    h3 {
                        margin: 0;
                        color: $blueColor;
                        //color: $blueLight;
                        font-weight: bold;
                        font-size: 20px;

                    }
                }

                &.rsvpRequired {

                    .text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 20px;                        
                    }

                }

                .flexCont {
                    width: 100%;
                    padding: 20px 10px 0;
                }
                
                
                p.showDates {
                    margin: 20px 0 0 0;
                    font-size: 16px;
                    color: $blueColor;
                    /* border-top: 1px solid $blueColor; */
                    background: $blueLogo;
                    color: white;
                    padding: 5px 0;
                    text-align: center;
                    border-radius: 0 0 15px 15px;
                }

                .day {

                    margin: 0px 0 10px 0; 

                    p {
                        margin: 0 5px 0;
                    }
                    
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                
            }

        }

    }

    .chooseArrive {

        background: url('../../img/icons/clockPage/arrowDown.svg') white 20px center no-repeat;
        border-radius: 20px;
        /* box-shadow: -1px 0px 5px 2px rgb(51 88 113 / 16%); */
        
        padding: 10px 17px 10px 40px !important;
        font-size: 15px;
        width: 125px;

        border: 1px solid $blueLight;
    
    }


}
