/* *************** MENU ************************* */

.SystemPages .content-wrapper.twoColumns {
  display: table;
  width: 100%;
}

.SystemPages .content-wrapper.twoColumns .site-menu {
  /* MENU */
  display: table-cell;
  position: relative;
  width: 329px;
  min-width: 329px;
  background: #0c213a;
}

.SystemPages .content-wrapper.twoColumns .mainTable-wrapper {
  /* CONTENT */
  display: table-cell;
  width: 100%;
  position: relative;
  background: #f5f5f5;
  padding: 0 0 20px 0;
}

.SystemPages .content-wrapper.twoColumns .site-menu .credit {
  /* position: absolute;
  bottom: 0; */
  width: 100%;
}

.SystemPages .mainMenuTable {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: #0c213a;

  -webkit-box-shadow: -1px 0px 5px 1px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1px 0px 5px 1px rgba(0, 0, 0, 0.29);
  box-shadow: -1px 0px 5px 1px rgba(0, 0, 0, 0.29);
}

.SystemPages .content-wrapper.twoColumns .site-menu header {
  text-align: center;
  padding: 30px 0 40px;
}

.SystemPages .content-wrapper.twoColumns .site-menu header img.logoClient {
  display: inline-block;
  width: 210px;
}

.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav {
  width: 85%;
  margin: 0 auto;
}

.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a {
  width: 100%;
  display: block;
  border-bottom: 1px solid #193960;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  min-height: 52px;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a:first-child,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a:first-child {
  border-top: 1px solid #193960;
}

.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a.is-active,
.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a:hover,
.SystemPages .content-wrapper.twoColumns .site-menu .mainMenuNav a:focus,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a.is-active,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a:hover,
.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav a:focus {
  /* font-family: "fb_bold","helvetica","arial"; */
  background: #1b3d65;
  border-bottom: none;
}

.SystemPages
  .content-wrapper.twoColumns
  .site-menu
  .mainMenuNav
  a
  img.bulletMainMenu,
.SystemPages
  nav.mobileNavbar
  .navbar-collapse
  .navbar-nav
  a
  img.bulletMainMenu {
  margin: 0 0 0 20px;
}

.SystemPages .site-menu .fixedPosition {
  /* position: fixed; */
  width: 329px;
  top: 0;
  bottom: 0;
}

.SystemPages nav.mobileNavbar {
  background: #2f4155 !important;
}

.SystemPages nav.mobileNavbar .navbar-collapse .navbar-nav {
  padding: 20px 0 10px 5%;
}

.SystemPages nav.mobileNavbar .navbar-brand {
  margin-left: 10px;
}

.SystemPages .NavInner {
  padding: 20px 2% 10px;
  background: white;
  position: relative;
  z-index: 10;

  -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.2);
}

.SystemPages .mainContent {
  padding: 20px 1.5% 10px;
  position: relative;
  min-width: 1100px;
}

.SystemPages .NavInner .searchNavInner input {
  background: #e9e9e9;

  border-radius: 0 30px 30px 0 !important;
  -moz-border-radius: 0 30px 30px 0 !important;
  -webkit-border-radius: 0 30px 30px 0 !important;
  padding-right: 20px;
  border-left: none;
}

.SystemPages .NavInner .searchNavInner button {
  border-radius: 30px 0 0 30px !important;
  -moz-border-radius: 30px 0 0 30px !important;
  -webkit-border-radius: 30px 0 0 30px !important;

  background: #e9e9e9;
  border: 1px solid #ced4da;
  border-right: none;
}

.SystemPages .NavInner .searchNavInner button:hover,
.SystemPages .NavInner .searchNavInner button:focus {
  color: #359ee5;
}

.SystemPages .NavInner .userLogout .userCircle {
  color: #2f4155;
  font-size: 40px;
  display: inline-block;
  vertical-align: middle;
}

.SystemPages .NavInner .userCont {
  min-width: 100%;
  text-align: left;
}
.SystemPages .NavInner .userLogout .userCont .logOutBtn {
  padding: 0;
  /* display: inline-block; */
  vertical-align: middle;
  color: #2f4155;
  background: none;
  border: none;
  font-size: 20px;
  margin: 5px 20px 0 0;
  float: left;
}

.SystemPages .NavInner .userLogout .userCont .logOutBtn:hover,
.SystemPages .NavInner .userLogout .userCont .logOutBtn:focus {
  color: #359ee5;
}

.BulletMenuCont {
  display: inline-block;
}

.BulletMenuCont .bCountCont {
  position: relative;
}

.BulletMenuCont .bCountCont .number {
  position: absolute;
  background: #f5325c;
  border: 2px solid #fbadbe;
  border-radius: 50%;
  font-size: 11px;
  height: 24px;
  text-align: center;
  width: 24px;
  padding: 2px 0 0 0;
  top: -5px;
  right: -16px;
}

.BulletMenuCont .bCountCont img {
  width: 30px;
}

/* *************** END MENU ************************* */

/* ------ GENERAL ----------- */

.customSelectInput {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #d4d7e6;
  font-size: 16px;
  margin: 13px 0 0 0;
  background: url("../img/icons/SVG/downSelect.svg") left 7px no-repeat !important;
  background-size: 17px;
  padding: 0 0px 12px 20px !important;
}

.input.inputTypesearchDB {
  position: relative;
  z-index: 10;
  background: white;
}


.customInputSearch .jZkBQk > .wrapper {
  position: relative;
  background: white;
  margin: 5px 0 0 0;
  border-radius: 0;
  border: none;

  
 
}

.customInputSearch .jZkBQk > .wrapper .sc-bwzfXH {
  position: relative;
  z-index: 10;
  box-shadow: rgb(32 33 36 / 28%) 0px 1px 6px 0px;

  height: 200px;
  overflow: hidden;
  overflow-y:auto;

}

.customInputSearch .jZkBQk > .wrapper .sc-bwzfXH ul {
  margin: -60px 0 0 0;
}

.customInputSearch .jZkBQk > .wrapper .sc-bxivhb {
  padding: 0 0 5px 0;
  border-bottom: 1px solid #d4d7e6;
}

.customInputSearch .jZkBQk > .wrapper:hover,
.customInputSearch .jZkBQk > .wrapper:focus {
  box-shadow: none;
}

.customInputSearch.errorClass .jZkBQk > .wrapper .sc-bxivhb {
  border-color: #dc3545;
}

.input .invalid-feedback {
  font-size: 14px;
}

.input .form-floating > .form-control {
  padding: 13px 0 0 0;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #d4d7e6;
  height: calc(3rem + 2px);
}

.input.inputTypedate .form-floating > .form-control {
  padding: 0 0 0px 0;
}

.input.inputTypedate .form-floating > .form-control:focus ~ label,
.input.inputTypedate
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label,
.input.inputTypedate .form-floating > .form-select ~ label {
  margin-top: 1px;
  padding: 0;
  font-size: 17px;
  /* opacity: 1; */
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
  color: #dc3545;
}

.input .form-floating > label {
  padding: 13px 0 0 0;
  margin-right: 0;
}

.inputs .rows2 .input {
  width: 46%;
}

.inputs .rows2 .input:nth-child(1) {
  float: right;
}
.inputs .rows2 .input:nth-child(2) {
  float: left;
}

.selectButtons label {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  margin: 0 0 0 30px;
}

.selectButtons label.errorClass {
  color: #dc3545;
}

.selectButtons .buttonsCont {
  display: inline-block;
  vertical-align: middle;
}

.selectButtons .buttonsCont button {
  border: 1px solid #d4d7e6;
  background: none;
  padding: 10px 10px;
  margin: 0 0 0 20px;
  font-size: 20px;
  min-width: 150px;
  text-align: right;
  border-radius: 10px;
}

.selectButtons .buttonsCont button:hover,
.selectButtons .buttonsCont button.selected {
  background-color: #f1f3fc;
  border-color: #1991d0;
}

.selectButtons .buttonsCont button img {
  width: 20px;
  margin: 0 0 0 15px;
}

.checkboxes .checkBoxCont label {
  margin: 0 10px 0 0;
}

.checkboxes .checkBoxCont .form-check-input {
  width: 13px;
  height: 13px;
  vertical-align: top;
  margin-top: 6px;
}
.checkboxes .checkBoxCont .form-check-input:checked {
  background-color: #1991d0;
  border-color: #1991d0;
}

.checkboxes .checkBoxCont label {
  font-size: 16px;
  color: black;
  vertical-align: top;
  display: inline-block;
  width: 90%;
}

.sendBlueBtn {
  background: #1991d0;
  border-color: #1991d0;
  padding: 10px 60px;
  color: white;
  display: inline-block;
}

.sendBlueBtn.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.sendBlueBtn:hover,
.sendBlueBtn:focus {
  background: #13618b;
  border-color: #13618b;
}

.sendBlueBtn.withDisabled.btn-disabled {
  background: grey;
  border-color: grey;
}

.sendBlueBtn.withDisabled.btn-disabled:hover,
.sendBlueBtn.withDisabled.btn-disabled:focus {
  color: white;
}

.singIn {
  padding: 20px 0 10px;
}

.singIn a {
  color: black;
  text-decoration: none;
  font-size: 16px;
}

.singIn a strong {
  color: #1991d0;
}

.singIn a:hover strong,
.singIn a:focus strong {
  text-decoration: underline;
}

.GreyPanel {
  background: white;
  border: 1px solid #e4e6ef;
  width: 100%;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgb(203 208 230 / 41%);
}

.inputsWithLabel label {
  font-family: "fb_bold", "helvetica", "arial";
  font-size: 15px;
}

.inputsWithLabel input,
.inputsWithLabel select {
  padding: 10px 50px 10px 10px;
  border-color: #e2e5ef;
  font-size: 17px;
}

.inputsWithLabel {
  position: relative;
}

.selectIcoBG {
  background-image: url("../img/icons/SVG/downSelect.svg");
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 17px;
}

.multiSelect.rmsc .dropdown-heading-value span {
  color: black;
}

.multiSelect .dropdown-content ul li {
  direction: ltr;
  text-align: right;
  padding: 0;
  margin: 0;
}

.multiSelect.rmsc {
  border-radius: 5px;
}

.multiSelect.rmsc .dropdown-container {
  border: 1px solid #e2e5ef;
  border-radius: 5px;
  direction: rtl;
}

/* .multiSelect.rmsc .dropdown-container ul li input  {
    
    display: none;
    
} */

.multiSelect.rmsc .dropdown-container ul li span {
  text-align: right;
  display: block;
  width: 100%;
}

.multiSelect.rmsc .dropdown-container .item-renderer {
  direction: rtl;
}

.multiSelect.rmsc .dropdown-container .item-renderer input {
  width: 20px;
  margin: 0 0 0 10px;
}

.multiSelect.rmsc .dropdown-container ul li {
  text-align: right;
  width: 100%;
}

.multiSelect.rmsc .dropdown-heading {
  padding-right: 50px;
  background: url("../img/sherut-leumi/svg/sherutPlaces/3.svg") right 13px
    center white no-repeat;
  background-size: 20px;

  font-size: 17px;
  height: 45px;
}

.multiSelect.disabled .dropdown-heading {
  background-color: #e9ecef;
}

/* .multiSelect  */

.selectIco2::after {
  content: "";
  bottom: 15px;
  left: 10px;
  position: absolute;
  width: 17px;

  height: 17px;

  background: url("../img/icons/SVG/downSelect.svg") center center no-repeat;
  background-size: 17px;
}

.searchIco input,
.searchIco select {
  background: url("../img/sherut-leumi/svg/search/lupa.svg") right 13px center
    white no-repeat;
  background-size: 20px;
}

.rakazIco input {
  background: url("../img/sherut-leumi/svg/search/rakaz.svg") right 13px center
    white no-repeat;
  background-size: 20px;
}

.rakazIco::after {
  background: url("../img/sherut-leumi/svg/search/rakaz.svg") center center
    white no-repeat;
  background-size: 20px;
}

.placeIco input {
  background: url("../img/sherut-leumi/svg/search/place.svg") right 13px center
    white no-repeat;
  background-size: 20px;
}

.placeIco::after {
  background: url("../img/sherut-leumi/svg/search/place.svg") center center
    no-repeat;
  background-size: 20px;
}

.inputsWithLabel.InputSearch .jZkBQk > .wrapper {
  position: relative;
  margin: 5px 0 0 0;
  border-radius: 5px;
  border: 1px solid #e2e5ef;
  padding: 0px 52px 0px 0;
}

.inputsWithLabel.InputSearch .jZkBQk > .wrapper input {
  color: black;
  font-size: 17px;
  font-family: "fb_regular", "helvetica", "arial";
}

.inputsWithLabel.InputSearch .jZkBQk > .wrapper .sc-bwzfXH {
  position: relative;
  z-index: 10;
  box-shadow: rgb(32 33 36 / 28%) 0px 1px 6px 0px;
}

.inputsWithLabel.InputSearch .jZkBQk > .wrapper .eOPJCx {
  display: none;
}

.inputsWithLabel.InputSearch .jZkBQk > .wrapper,
.inputsWithLabel.InputSearch .jZkBQk > .wrapper:hover,
.inputsWithLabel.InputSearch .jZkBQk > .wrapper:focus {
  box-shadow: none;
}

.inputsWithLabel.InputSearch .wrapper {
  background: url("../img/sherut-leumi/svg/search/place.svg") right 13px top
    11px white no-repeat;
  background-size: 23px;
}

.inputsWithLabel.InputSearch .jZkBQk > .wrapper .sc-bwzfXH {
  box-shadow: none;
}
.inputsWithLabel.InputSearch .jZkBQk > .wrapper .sc-bwzfXH ul {
  /* background: rgb(238, 238, 238); */
  padding: 0 10px;
}

.noResults {
  margin: 20px 0 0 0;
  color: red;
  font-size: 25px;
}

.goUpPic {
  display: none;
  width: 40px;
  position: fixed;
  bottom: 20px;
  cursor: pointer;
}

body.header-scroll .goUpPic {
  display: block;
}

/* ------ END GENERAL ----------- */

.PagesRegister.Desktop {
  background: right 20px no-repeat transparent;
  padding: 0 0 20px 0;
  min-height: 1012px;
}

.PagesRegister .firstRegister {
  width: 770px;
  float: left;
  background: #ffffffe0;
  margin: 20px 0 0 10%;
  padding: 40px 100px 30px;
  border: 1px solid #e4e6ef;
  border-radius: 5px;
  position: relative;
  z-index: 10;

  -webkit-box-shadow: 0px 0px 5px 2px rgba(203, 208, 230, 0.41);
  -moz-box-shadow: 0px 0px 5px 2px rgba(203, 208, 230, 0.41);
  box-shadow: 0px 0px 5px 2px rgba(203, 208, 230, 0.41);
}

.PagesRegister .firstRegister header {
  text-align: center;
  margin: 0 0 20px 0;
}

.PagesRegister .firstRegister header h1 {
  font-size: 30px;
  margin: 0 0 10px 0;
}

.PagesRegister .firstRegister header p {
  font-size: 17px;
}

.firstRegister .inputs {
  width: 540px;
  margin: 0 auto;
}

.firstRegister.loginPage .inputs {
  width: 310px;
}

.firstRegister .inputs .rows3 .input {
  float: right;
  width: 46%;
}

.firstRegister .inputs .rows3.inputs3 .input {
  width: 32%;
  margin: 0 0 0 1.5%;
}

.firstRegister .inputs .rows3.inputs3 .input .form-floating>.form-control:not(:placeholder-shown)~label {

  margin-right: 0;
}

.firstRegister .inputs .rows3.inputs3 .input:last-child {
  margin-left: 0;
  float: left;
}

.firstRegister .inputs .rows3 .phoneInputs {
  float: left;
  width: 46%;
  direction: ltr;
}

.firstRegister .inputs .rows3 .phoneInputs .input {
  float: left;
  text-align: right;
  direction: rtl;
}

.firstRegister .inputs .rows3 .phoneInputs .input:nth-child(2) {
  float: right;
  width: 53%;
}

.firstRegister .inputs .rows3 .phoneInputs .customSelectInput {
  width: 70px;
  float: left;
  padding-right: 0;
}

.firstRegister .line {
  margin: 0 0 0px 0;
  min-height: 76px;
}

.firstRegister .inputs.Sherut .input.fullW .input {
  width: 100%;
}

.phoneInputs .input .form-floating > label {
  font-size: 16px;
  /* padding: 20px 0 0 0; */
}

.phoneInputs .form-floating > .form-control:focus ~ label,
.phoneInputs .form-floating > .form-control:not(:placeholder-shown) ~ label,
.phoneInputs .form-floating > .form-select ~ label {
  font-size: 12px;
  padding: 13px 0 0 0;
}

.phoneInputs .input .form-floating > .form-control {
  padding: 5px 0 0 0;
  font-size: 16px;
}

.phoneInputs .inputTypeselect .invalid-feedback {
  float: left;
  padding: 0 25px 0 0;
}

.PagesRegister .firstRegister .line.selectButtons {
  margin: 15px 0 0 0;
}

.PagesRegister .firstRegister .line.checkboxes {
  margin: 10px 0 20px 0;
}
.PagesRegister .firstRegister .line.checkboxes .checkBoxCont {
  margin: 0px 0 5px 0;
}

.firstRegister.loginPage {
  margin-top: 4%;
}

.firstRegister.loginPage .inputs .singIn {
  padding: 30px 0;
  margin: 20px 0 0 0;
}

.firstRegister.loginPage .inputs .singIn.reSendPass {
  padding: 0;
  margin: 0 0 50px 0;
  text-align: left;
}

.alertModal.error .singIn.errorIsUser .jumpPage {
  font-size: 20px;
}

.mainMenuTable .userData {
  margin: 30px 0 0 0;
}

.mainMenuTable .userData .avatarPicture {
  
  background: #cadaef;
  width: 160px;
  margin: -18px auto 0;
  border-radius: 30px;
  padding: 2px;

}

.mainMenuTable .userData .avatarPicture img {

  height: 140px;

}

.mainMenuTable .userData figure {
  width: 67px;
  height: 67px;
  margin: 0 auto;
  border: 1px solid white;
  border-radius: 50%;
  padding: 5px 0 0 0;
}

.mainMenuTable .userData figure div {
  border-radius: 50%;
  width: 57px;
  height: 57px;
  margin: 0 auto;
  background: center center no-repeat white;
  background-size: cover;
}

.mainMenuTable .userData .textCont {
  margin: 10px 0 0 0;
}

.mainMenuTable .userData .textCont h3 {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.SearchContainer {
  max-width: 1100px;
  margin: 30px auto 0;
}

.SearchUpCont {
  padding: 20px 30px 30px;
}

.SearchUpCont .line.seccondLine {
  text-align: right;
  margin: 20px 0 0 0;
}

.SearchUpCont .line.seccondLine .inputsWithLabel label {
  text-align: right;
  display: block;
  padding: 0;
}

.SearchUpCont .line.seccondLine .inputsWithLabel select {
  width: 100%;
}

.SearchUpCont .line.seccondLine .inputsWithLabel,
.SearchUpCont .line.seccondLine button {
  display: inline-block;
  vertical-align: bottom;
  width: 33%;
  margin: 0 0 0 2%;
}

.SearchUpCont .line.seccondLine button {
  width: 30%;
  margin-left: 0;
  padding: 7px 60px;
}

.SearchUpCont .line.inputs4 {
  position: relative;
}

.SearchUpCont .btnClear {
  position: absolute;
  left: 0px;
  bottom: -10px;
  border: none;
  background: none;
  font-size: 16px;
  width: 18%;
}

.SearchUpCont .btnClear:hover {
  text-decoration: underline;
}

.SearchResults {
  margin: 30px 0 0 0;
}

.searchRow {
  margin: 0 0 20px 0;
}

.searchRow .tableSearchRow {
  display: table;
  width: 100%;
}

.searchRow .tableSearchRow .coltableSearch {
  display: table-cell;
  vertical-align: top;
  padding: 10px;
}

.searchRow .tableSearchRow .coltableSearch.picCol {
  width: 10%;
}

.searchRow .tableSearchRow .coltableSearch.dataCol {
  padding: 15px 10px 0 0;
  width: 70%;
}

.searchRow .tableSearchRow .coltableSearch.BtnsCol {
  padding: 0;
  width: 20%;
  vertical-align: middle;
}

.searchRow .tableSearchRow .coltableSearch.picCol figure {
  width: 140px;
  height: 120px;
  background: center center no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
  border-radius: 10px;
}

.ShowDataInRow {
  /* border-left: 1px solid #E4E6EF; */
  padding: 0 0 20px 20px;
}

.ShowDataInRow header h2 {
  font-size: 18px;
  font-family: "fb_bold", "helvetica", "arial";
  margin: 0;
}

.ShowDataInRow header p {
  width: 100%;
  height: 50px;
  font-size: 16px;
  overflow: hidden;
}

ul.iconsLine li {
  display: inline-block;
  margin: 0 0 0 10px;
  border-left: 2px solid #e4e6ef;
  padding-left: 10px;
}

ul.iconsLine li:last-child {
  border-left: none;
  padding-left: 0;
}

/* .showMoreData ul.iconsLine li:last-child {
    
} */

ul.iconsLine li img {
  margin: 0 0 0 5px;
  vertical-align: middle;
}

ul.iconsLine li span {
  font-size: 15px;
  vertical-align: middle;
}

ul.iconsLine li p {
  font-size: 15px;
  vertical-align: middle;
  display: inline-block;
  margin: 0;
}

ul.iconsLine.fullLine {
  display: block;
}

ul.iconsLine.fullLine li {
  display: inline-block;
  width: initial;
}

ul.iconsLine li a {
  color: black;
  text-decoration: none;
}

ul.iconsLine li a:hover,
ul.iconsLine li a:focus {
  text-decoration: underline;
}

ul.iconsLine .goSayarot {
  font-size: 14px;
  margin: 0 10px 0 0;
  font-weight: bold;
  display: inline-block;
}

ul.iconsLine .goSayarot span {
  vertical-align: middle;
}

.searchRow .buttonsCont {
  width: 137px;
  margin: 0 auto;
  text-align: center;
}

.searchRow .buttonsCont .sendBlueBtn {
  padding: 10px 0;
  text-align: center;
  width: 100%;
  font-size: 17px;
}

.searchRow .buttonsCont .sendBlueBtn.actived {
  background-color: #d0192c;
}

.searchRow .buttonsCont .sendBlueBtn:disabled {
  opacity: 0.5;
  background-color: grey;
}

.searchRow .buttonsCont .sendBlueBtn img {
  margin: 0 0 0 7px;
}

.searchRow .buttonsCont button.showMoreData {
  background: none;
  border: none;
  margin: 6px 0 0 0;
  font-size: 16px;
  padding: 0px;
  color: black;
}

.searchRow .buttonsCont button.showMoreData:hover,
.searchRow .buttonsCont button.showMoreData:focus {
  text-decoration: underline;
}

.showMoreData {
  margin: 0px 0 0 0;
  padding: 0px 173px 40px 80px;
}

.SherutPlacesIndex .showMoreData {
  margin: 0;
  padding: 0px 160px 40px 80px;
}

.showMoreData h3 {
  font-size: 18px;
  font-family: "fb_bold", "helvetica", "arial";
  margin: 0 0 5px 0;
}

.showMoreData article {
  margin: 0 0 20px 0;
  overflow: hidden;
  font-size: 17px;
}

.showMoreData ul {
  width: 100%;
}

.showMoreData ul li {
  display: block;
  padding: 0 0px 0 10px;
  border: none;
}

.SherutPlacesIndex .showMoreData ul li {
  padding-left: 0;
  margin-bottom: 10px;
  border-left: none;
}

.deleteSayeretModal .modal-content .Message {
  padding: 20px 0 0 0;
  text-align: center;
}

.deleteSayeretModal h2 {
  font-family: "fb_bold", "helvetica", "arial";
  font-size: 25px;
}

.deleteSayeretModal p {
  font-size: 25px;
}

.deleteSayeretModal img.warningPic {
  position: absolute;
  width: 80px;
  top: -40px;
  margin: 0 -40px 0 0;
  right: 50%;
}

.deleteSayeretModal .buttons {
  text-align: center;
  margin: 40px 0 0 0;
}

.deleteSayeretModal .buttons button {
  margin: 0 10px;
  padding: 6px 50px;
  font-size: 18px;
}

.SearchCategoty .changePage {
  text-align: center;
  margin: 0 0 40px 0;
}

.SearchCategoty .changePage .buttonsCont button {
  border: 1px solid #cadaef;
  border-radius: 10px;
  padding: 10px 0;
  width: 150px;
  background: white;
  color: black;
}

.SearchCategoty .changePage .buttonsCont button:nth-child(1) {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.SearchCategoty .changePage .buttonsCont button:nth-child(2) {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.SearchCategoty .changePage .buttonsCont button:hover,
.SearchCategoty .changePage .buttonsCont button:focus {
  text-decoration: underline;
}

.SearchCategoty .changePage .buttonsCont button.active {
  background: #1991d0;
  color: white;
}

.DataSection header {
  padding: 50px 0 0 0;
  text-align: center;
}

.DataSection header h1 {
  font-family: "fb_bold", "helvetica", "arial";
  font-size: 25px;
  margin: 0;
}

.loaderInfiniteScroll {
  display: block;
  margin: 0 auto;
  width: 100px;
}

.AccordionCont {
  position: relative;
  width: 770px;
  margin: 30px auto 0;
}

.AccordionCont .accordions {
  margin: 0 0 40px 0;
}

.AccordionCont .accordion {
  border-radius: 10px 10px 0 0 !important;
}

.AccordionCont .accordion .accordion-button::after {
  top: 15px;
  left: 30px;

  background-image: url("../img/sherut-leumi/svg/userData/open.png");
  background-size: 100%;
  width: 25px;
  height: 25px;
  position: absolute;
}

.AccordionCont .accordion-item {
  border-radius: 10px 10px 0 0 !important;
  border: none;
}

.AccordionCont .accordion-header button {
  width: 100%;
  display: block;
  font-size: 20px;
  text-align: center;
  color: #0f6998;
  font-family: "fb_bold", "helvetica", "arial";
  background-color: #efeff8;
  border-radius: 10px 10px 0 0 !important;
}

.AccordionCont .accordion-header button:focus,
.AccordionCont .accordion-header button:hover {
  box-shadow: none;
}

.AccordionCont .accordion-body {
  box-shadow: 0px 0px 5px 2px rgb(203 208 230 / 41%);
  border: none;
  padding: 50px 0;
}

.AccordionCont .accordion button.collapsed {
  box-shadow: 0px 3px 5px 1px rgb(0 0 0 / 20%);
  border-radius: 10px !important;
}

.AccordionCont .accordion .accordion-header button img {
  position: absolute;
  right: 25px;
  top: 8px;
}

.showFilters {
  background: #d9e2e9;
  border-radius: 5px;
  margin: 20px 0 0 0;
  padding: 10px 20px;
}

.showFilters ul li {
  display: inline-block;
  margin: 0 0 0 10px;
}

.showFilters ul li.title {
  margin: 0 0 0 10px;
}

.showFilters ul li button {
  background: url("../img/sherut-leumi/svg/xRed.svg") 10px center #eaf1f6
    no-repeat;
  border: 1px solid #e4e6ef;
  background-size: 10px;
  padding: 5px 10px 5px 40px;
  border-radius: 5px;
  font-size: 16px;
}

.showFilters ul li button:hover {
  background-color: white;
}

.orderBy select {
  width: 170px;
  background-position: 14px 13px !important;
  background-color: #e6eef2 !important;
  padding: 10px 10px 10px 40px !important;
  margin: 30px 0 0 0;
}

.SherutPlacesIndex h1 {
  font-family: "Karantina-Regular", "helvetica", "arial";
  text-align: center;
  letter-spacing: -1px;
  padding: 10px 0;
  margin: 20px 0 0 0;
  font-size: 45px;
}

.placesContainer {
  width: 1100px;
  margin: 40px auto 0;
  position: relative;
}

.SearchUpCont .inputs3 .inputsWithLabel {
  width: 32%;
  display: inline-block;
  margin: 0 0 10px 2%;
}
.SearchUpCont .inputs3 .inputsWithLabel select,
.SearchUpCont .inputs3 .inputsWithLabel input {
  width: 100%;
}

.SearchUpCont .inputs3 .inputsWithLabel:last-child {
  margin-left: 0;
}

.SearchUpCont .inputs4 .input3Cont {
  display: inline-block;
  width: 80%;
  margin: 0 0 0 2%;
}

.SearchUpCont .inputs4 .input3Cont.inputs3 .inputsWithLabel {
  width: 27%;
}

.SearchUpCont .inputs4 .input3Cont.inputs3 .inputsWithLabel label,
.SearchUpCont .inputs4 .input3Cont.inputs3 .inputsWithLabel select {
  width: 100%;
}

.SearchUpCont .inputs4 .input3Cont.inputs3 .inputsWithLabel.searchIco {
  width: 42%;
}

.SearchUpCont .inputs4 .input3Cont.inputs3 .inputsWithLabel.searchIco input {
  width: 100%;
}

.SearchUpCont .inputs4 .sendBlueBtn {
  display: inline-block;
  width: 18%;
  padding: 0px 10px;
  font-size: 19px;
  line-height: 45px;
  margin: 0 0 1px 0;
}

.maslulIco input {
  background: url("../img/sherut-leumi/svg/sherutPlaces/1.svg") right 13px
    center white no-repeat;
  background-size: 20px;
}

.maslulIco::after {
  background: url("../img/sherut-leumi/svg/sherutPlaces/1.svg") center center
    no-repeat;
  background-size: 20px;
}

.shanaIco input {
  background: url("../img/sherut-leumi/svg/sherutPlaces/2.svg") right 13px
    center white no-repeat;
  background-size: 20px;
}

.shanaIco::after {
  background: url("../img/sherut-leumi/svg/sherutPlaces/2.svg") center center
    no-repeat;
  background-size: 20px;
}

.thumIco input {
  background: url("../img/sherut-leumi/svg/sherutPlaces/3.svg") right 13px
    center white no-repeat;
  background-size: 20px;
}

.thumIco::after {
  background: url("../img/sherut-leumi/svg/sherutPlaces/3.svg") center center
    no-repeat;
  background-size: 20px;
}

.ezorIco input {
  background: url("../img/sherut-leumi/svg/sherutPlaces/4.svg") right 13px
    center white no-repeat;
  background-size: 20px;
}

.ezorIco::after {
  background: url("../img/sherut-leumi/svg/sherutPlaces/4.svg") center center
    no-repeat;
  background-size: 20px;
}

.iconAfter::after {
  content: "";
  bottom: 23px;
  right: 15px;
  position: absolute;
  width: 20px;
  height: 20px;
}

.iconAfter2::after {
  content: "";
  bottom: 13px;
  right: 15px;
  position: absolute;
  width: 20px;
  height: 20px;
}

.SherutPlacesIndex {
  background: white;
  min-height: auto;
  padding-bottom: 20px;
}

.SherutPlacesIndex .topPic {
  display: block;
  margin: 0 auto;
}

.SherutPlacesIndex .GreyPanel {
  background: #f6f7f8;
}

.carouselGallery {
  text-align: center;
}

.carouselGallery button {
  display: inline-block;
  vertical-align: top;
  width: 37px;
  height: 32px;
  overflow: hidden;
  padding: 0;

  border: 1px solid rgb(224, 224, 224);
  border-radius: 5px;

  /* background-color: #cae0ed; */

  margin: 0 2px;
}

.carouselGallery button:first-child {
  width: 140px;
  height: 120px;
  margin: 0 0 10px 0;
  overflow: hidden;
  padding: 0;
}

.carouselGallery button img {
  min-height: 32px;
}

.carouselGallery button:first-child img {
  display: inline-block;
  min-height: 120px;
}

.carouselGallery button img {
  width: 100%;
}

.modalPictures .modal-dialog {
  margin: 5% auto;
  border-radius: 10px;
}

.modalPictures .modal-body {
  background: black;
  border-radius: 0.3rem;
}

.modalPictures .modal-body .carousel {
  display: block;
  width: 750px;
  margin: 0 auto;
  text-align: center;
}

.modalPictures .modal-body .closeBtn {
  color: white;
}

.modalPictures .modal-body .carousel {
  margin: 20px 0 0 0;
}

.modalPictures .modal-body .carousel .carousel-item img {
  height: 600px;
}

.moreDataPlaces {
  margin: 20px 0 0 0;
}

.moreDataPlaces h4 {
  font-family: "fb_bold", "helvetica", "arial";
  font-size: 18px;
  margin: 0;
}

.shareHeader {
  text-align: center;
}

.shareHeader h2 {
  margin: 0 0px 10px 0;
}

.shareHeader a {
  background: #1991d0;
  display: inline-block;
  padding: 5px 20px;
  color: white;
  text-decoration: none;
  border-radius: 10px;
}

.shareHeader a:hover,
.shareHeader a:focus {
  background: grey;
}

.iconsLine .shares {
  display: inline-block;
  margin: 0 10px 0 0;
}

.iconsLine .shares button {
  margin: 0 0 0 7px;
}

.iconsLine .shares button:last-child {
  margin-left: 0;
}

.sherutPlaceButtons {
  text-align: left;
  padding: 10px 0 0 10%;
  border-right: 1px solid #e4e6ef;
}

.sherutPlaceButtons button {
  width: 170px;
  background: #d9e0ec;
  margin: 0 0 10px 0;
  border: none;
  color: black;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: right;
  font-size: 15px;
}

.SherutPlacesIndex .searchRow.GreyPanel.activeFavorite {
  background: #e7eff8;
}

.sherutPlaceButtons button.active {
  background: white;
  /* border: 1px solid #1991D0; */
}

.sherutPlaceButtons button img {
  margin: 0 0 0 10px;
  width: 20px;
  vertical-align: middle;
}

.sherutPlaceButtons button span {
  vertical-align: middle;
}

.sherutPlaceButtons button:hover {
  background: white;
}

.SherutPlacesIndex .showFavoritesRecords {
  width: 250px;
  display: block;
  background: white;
  margin: 30px auto;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
}

.SherutPlacesIndex .showFavoritesRecords:hover {
  background: #d9e0ec;
  font-weight: bold;
}

.SherutPlacesIndex .showFavoritesRecords span {
  vertical-align: middle;
}

.SherutPlacesIndex .showFavoritesRecords img {
  width: 30px;
  margin: 0 0 0 10px;
  vertical-align: middle;
}

.SherutPlacesIndex
  .SearchCategoty
  .changePage
  .buttonsCont
  button:nth-child(2) {
  width: 180px;
}

.SherutPlacesIndex
  .SearchCategoty
  .changePage
  .buttonsCont
  button:nth-child(2).disabled {
  color: black;
  opacity: 0.3;
}

.SherutPlacesIndex
  .SearchCategoty
  .changePage
  .buttonsCont
  button:nth-child(2)
  img {
  width: 24px;
  margin: 2px 0 0 5px;
  vertical-align: top;
}

.messaggePopUp {
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  z-index: 20;
  text-align: center;
  padding: 0 0 0 20%;
}

.messaggePopUp .messaggeCont {
  background: #4d4d4d;
  padding: 10px 50px;
  font-size: 20px;
  display: inline-block;

  border-radius: 0 0 20px 20px;
}

.messaggePopUp .messaggeCont img {
  margin: 0 0 0 10px;
}

.twoColums {
  column-count: 2;
  column-rule: 2px solid #e4e6ef;
}

.ShowSocialNetworks {
  margin: 0 0 20px 0;
  text-align: center;
}

.ShowSocialNetworks ul li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
  height: 35px;
}

.ShowSocialNetworks ul li img {
  width: 25px;
}

.ShowSocialNetworks ul li a:hover img,
.ShowSocialNetworks ul li a:focus img {
  width: 35px;
}

.SystemPages h1 {
  text-align: center;
  font-size: 30px;
  padding: 10px 0 20px;
}

.callBtn {
  background: #008cd3;
  color: white !important;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 12px;
  margin: 0 5px 0 0;
}

.bannerCall {
  border-radius: 5px;
  background: #e1e3e5;
  text-align: center;
  margin: 10px 0 0 0;
  padding: 5px 0;
  color: #0c213a;
}

.bannerCall .fas {
  color: #f5325c;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;
}

.bannerCall h2 {
  display: inline-block;
  vertical-align: middle;
  font-size: 19px;
  margin: 0;
  font-family: "fb_bold", "helvetica", "arial" !important;
}

.bannerCall p {
  display: inline-block;
  margin: 0 10px 0 0px;
  vertical-align: middle;
}

.linkToSite {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  width: 320px;
  height: 420px;
  /* background: red; */
  background: transparent;
  margin: 5% 0 0 0;
  color: transparent;
  font-size: 1px;
  text-decoration: none;
}

.btnsUp {
  width: 1100px;
  margin: 10px auto -50px;
  position: relative;
  text-align: left;
  padding: 10px 0 0 0;
}

.btnsUp a {
  color: white;
  text-decoration: none;
  background: #1991d0;
  padding: 5px 10px;
  border-radius: 10px;
}

.btnsUp a:hover,
.btnsUp a:focus {
  color: white;
  background: black;
}

.filesIndex .pIndex {

  
  width: 80%;
  margin: -10px auto 20px;
  text-align: center;

}

.filesIndex .documentCont {

  padding: 0 10%;


}

.filesIndex .documentCont .document {

  text-align: center;
  width: 100%;
  
}

.printView .showHtml {
    background: white;
    padding: 10px;
    max-width: 1000px;
    margin: 10px auto;
}

.showInfoPopUp {

  text-align: center;
  margin: -10px 0 30px 0;

}

.showInfoPopUp > button {

    background: #E88310;
    padding: 10px 25px;
    border: none;
    border-radius: 10px;
    color: white;

}

.Mobile .showInfoPopUp {
  margin-top: 0;
}

.Mobile .showInfoPopUp > button {
  font-size: 17px;
  
}

.showInfoPopUp > button img {
  margin:  0 0 0 10px;
}

.showInfoPopUp > button:hover {
  background: black;
}

.modalSite.welcomeModal .modal-dialog {
  margin-top: 5%;
}

.modalSite.welcomeModal h2 {
  color:#1991d0;
  margin: 0 0 20px 0;
}

.modalSite.welcomeModal .p1 {
  font-weight: bold;
  padding: 0 10%;
  margin: 0 0 10px 0;

}

.modalSite.welcomeModal .p2 {
  color:#1991d0;
  font-weight: bold;
  max-width: 400px;
  margin: 0 auto;
}

.modalSite.welcomeModal ol {
  width: 90%;
  margin: 0 auto 20px;
}

.modalSite.welcomeModal ol li {

  margin: 0 0 10px 0;

}

.textDayShowSayarot > p {
  font-weight: bold;
  margin: 0;
}

.textDayShowSayarot .newText {

   margin: 20px 0 0px 0;

}

.textDayShowSayarot .newText h3 {
  margin: 0 0 0px 0;
  font-size: 30px;
}

.mobileClass .newText h3 {
  padding: 0 0%;
  font-size: 27px;

}

.textDayShowSayarot .newText p {
  font-weight: bold;
  color: #1991d0;
  margin: 0;
  display: block;
  padding: 0 10%;
}

.mobileClass .newText p {
  
  font-size: 16px;
  display: inline-block;
  margin: 0 auto;
  padding: 0 10%;

}

.textDayShowSayarot .newText .newp {

  margin: 20px 0 0 0;

}

.textDayShowSayarot .newText .newp strong {

  display: block;
  background: white;
  padding: 3px 0;

}

.closeAppIframe {

  /* position: absolute;
  top: 206px;
  left: 20px;
  width: 1030px;
  height: 620px; */

  width: 400px;
  height: 704px;
  border-radius: 20px;
  margin: 0 auto;
  display: block;
  border: 4px solid black;

}

.closeAppIframe.popup {

  right: 10px;
  left: inherit;

  width: 700px;
  height: 400px;

}

.Mobile .closeAppIframe {
 
  width: 100vw;
  height: 100vh;

}

.my-sayarot {
  padding: 20px;
}

.my-sayarot .sayarot-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.my-sayarot .searchRow {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.my-sayarot .searchRow:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.my-sayarot .searchRow .buttonsCont .sendBlueBtn.actived {
  background-color: #d0192c;
  color: white;
}

.my-sayarot .searchRow .buttonsCont .sendBlueBtn {
  background-color: #1991d0;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.my-sayarot .searchRow .buttonsCont .sendBlueBtn:hover {
  opacity: 0.9;
}

.my-sayarot .searchRow .buttonsCont .showMoreData {
  color: #1991d0;
  text-decoration: none;
  margin-top: 10px;
  font-size: 14px;
}

.my-sayarot .searchRow .buttonsCont .showMoreData:hover {
  text-decoration: underline;
}

.my-sayarot .ShowDataInRow header h2 {
  color: #333;
  font-size: 20px;
  margin-bottom: 5px;
}

.my-sayarot .ShowDataInRow header p {
  color: #666;
  font-size: 16px;
  margin-bottom: 15px;
}

.my-sayarot .iconsLine {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
}

.my-sayarot .iconsLine li {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #555;
}

.my-sayarot .iconsLine li img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.my-sayarot .noResults {
  text-align: center;
  color: #666;
  font-size: 18px;
  margin-top: 30px;
}

.my-sayarot .error-message {
  text-align: center;
  color: #d0192c;
  margin-top: 30px;
}

.my-sayarot .error-message h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.my-sayarot .error-message p {
  font-size: 16px;
  color: #666;
}

.password-input-container {
  position: relative;
}

.password-input-container label {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 12px;
  color: #666;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  z-index: 1;
}

.password-input-container .form-control:placeholder-shown + label {
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.password-input-container .form-control:focus + label,
.password-input-container .form-control:not(:placeholder-shown) + label {
  top: -20px;
  font-size: 12px;
  color: #1991d0;
}

.password-toggle-btn {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #666;
  z-index: 2;
}

.password-toggle-btn:hover {
  color: #333;
}

.password-toggle-btn:focus {
  outline: none;
}

.password-input-container .customFloatInput {
  padding-left: 35px;
}