/* MOBILE MENU! */

.mobileMenu .MuiPaper-root {
  background: #0c213a;
  color: white;
}

.mobileMenu .m_header {
  position: relative;
  padding: 30px 0;
}

.mobileMenu .m_header .fakeClose {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 15px;
}

.mobileMenu .m_header .logoClient {
  display: block;
  width: 200px;
  margin: 10px auto;
}

.mobileMenu .m_header .logoClient img {
  width: 100%;
  display: block;
}

.mobileMenu .userData {
  margin: 30px 0 0 0;
}

.mobileMenu .userData figure {
  display: inline-block;
  padding: 0 10% 0 0;
  vertical-align: middle;
}

.mobileMenu .userData figure div {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background: center center no-repeat white;
  background-size: cover;
}

.mobileMenu .userData .textCont {
  margin: 0px 10px 7px 0;
  display: inline-block;
  vertical-align: middle;
}

.mobileMenu .userData .textCont h3 {
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.mobileMenu .mainMenuNav {
  padding: 0 10% 0 0;
}

.mobileMenu .mainMenuNav a {
  display: block;
  width: 100%;
  color: white;
  margin: 0 0px 12px 0;
  text-decoration: none;
  font-size: 18px;
}

.mobileMenu .mainMenuNav a span {
  margin: 0 10px 0 0;
}

/* END MOBILE MENU! */

.mobileOpenBtn {
  z-index: 10;
  position: absolute !important;
  top: 0;
  margin: 39% 0 0 0 !important;
}

/* // GENERAL MOBILE! */

.Mobile.PagesRegister .firstRegister {
  background: #f6f7f8;
}

.Mobile .firstRegister .inputs .rows3 .phoneInputs .input.inputTypeselect {
  background-color: white !important;
}

.Mobile .loader {
  right: 50%;
}

.Mobile.PagesRegister .firstRegister {
  width: 90%;
  float: none;
  margin: 0 auto;
  padding: 20px 0;
  box-shadow: none;
  border: none;
}

.Mobile.PagesRegister .firstRegister .inputs {
  width: 100%;
  background: white;
  padding: 10px 5%;
}

.Mobile .inputs .topZindex {
  z-index: 20;
}

.Mobile .firstRegister.loginPage .inputs {
  background: transparent;
}

.Mobile.PagesRegister .firstRegister .line {
  min-height: 80px;
}

.Mobile .input.inputTypedate .form-floating > .form-control {
  padding: 16px 0 0 0;
}

.Mobile .topPic {
  margin: 0 0 20px 0;
}

.Mobile .selectButtons label {
  margin: 0 0 0 0;
  width: 10%;
}

.Mobile .selectButtons .buttonsCont {
  width: 90%;
}

.Mobile .selectButtons .buttonsCont button {
  width: 43%;
  margin-left: 5%;
  min-width: inherit;
  display: inline-block;
  font-size: 18px;
}

.Mobile .selectButtons .buttonsCont button:nth-child(2) {
  margin-left: 0;
}

.Mobile.UserConsole .topPic {
  padding: 10px 3% 0;
}

.Mobile .SearchContainer {
  margin: 0;
  max-width: 100%;
}

.Mobile .SearchCategoty .changePage {
  margin: 0 0 0px 0;
}

.Mobile .SearchCategoty .changePage button {
  font-size: 16px;
  padding: 5px 0;
  width: 120px;
}

.Mobile .SearchUpCont {
  padding: 10px 5%;
  background: none;
  box-shadow: none;
  border: none;
}

.Mobile .SearchUpCont .line.seccondLine {
  margin: 5px 0 0 0;
}

.Mobile .SearchUpCont .line.seccondLine .inputsWithLabel {
  width: 48%;
}

.Mobile .SearchUpCont .line.seccondLine .inputsWithLabel.placeIco {
  margin: 0;
  float: left;
}

.Mobile .SearchUpCont .line.seccondLine .inputsWithLabel select {
  width: 100%;
}

.Mobile .SearchUpCont .line.seccondLine button {
  margin: 15px 0 0 0;
  width: 100%;
  display: block;
}

.Mobile .searchRow .tableSearchRow .coltableSearch.picCol {
  width: 30%;
}

.Mobile .searchRow .tableSearchRow .coltableSearch.picCol figure {
  width: 100%;
  height: 90px;
}

.Mobile .SearchResults {
  padding: 0 2.5%;
}

.Mobile .searchRow .tableSearchRow .coltableSearch.dataCol {
  width: 70%;
}

.Mobile .searchRow .tableSearchRow .ShowDataInRow header p {
  height: inherit;
  margin: 0;
}

.Mobile .SearchResults .ShowDataInRow {
  border: none;
}

.Mobile .SearchResults .buttonsCont {
  width: 100%;
  padding: 10px;
}

.Mobile .SearchResults .buttonsCont button {
  width: 50%;
}

.Mobile .DataSection header {
  padding: 0;
}

.Mobile .AccordionCont {
  width: 100%;
  padding: 0 5%;
}

.Mobile .AccordionCont .accordion-body {
  padding: 20px 5%;
}

.Mobile .AccordionCont .accordion .accordion-header button img {
  width: 30px;
  top: 14px;
  right: 17px;
}

.Mobile .AccordionCont .accordion .accordion-button::after {
  width: 20px;
  height: 20px;
  left: 16px;
  top: 20px;
}

.Mobile .AccordionCont .accordion {
  margin-bottom: 20px;
}

.Mobile .firstRegister .inputs {
  width: 100%;
}

.Mobile .inputs .rows2 .input {
  width: 100%;
  float: none;
  margin: 0 0 20px 0;
}

.Mobile .showFilters {
  width: 90%;
  margin: 10px auto;
}

.Mobile .orderBy {
  margin: 0 5% 0 0;
}

.Mobile .orderBy select {
  margin-top: 0;
}

.Mobile .showFilters {
  padding-top: 25px;
}

.Mobile .showFilters ul li {
  margin-bottom: 15px;
}

.Mobile .showMoreData ul {
  display: block;
}

.Mobile .showMoreData ul li {
  display: block;
  width: 100%;
  margin: 0 0 5px 0;
  border-left: none;
}

.Mobile .placesContainer {
  width: 100%;
  margin: 20px auto 0;
}

.Mobile .SherutPlacesIndex h1 {
  font-size: 26px;
  padding: 0 5%;
  letter-spacing: 0;
}

.Mobile.MekomotSherutIndex header {
  padding: 10px 0;
  text-align: center;
}

.Mobile.MekomotSherutIndex .topPic {
  margin: 0;
}

.Mobile.MekomotSherutIndex .SearchUp {
  margin: 0 auto;
  width: 95%;
}

.Mobile.MekomotSherutIndex .SearchUpCont .inputs3 .inputsWithLabel,
.Mobile.MekomotSherutIndex .SearchUpCont .inputs4 .input3Cont,
.Mobile.MekomotSherutIndex
  .SearchUpCont
  .inputs4
  .input3Cont.inputs3
  .inputsWithLabel.searchIco {
  width: 100%;
  margin: 0 0 5px 0;
}

.Mobile.MekomotSherutIndex .SearchUpCont.GreyPanel {
  margin: 0 0 10px 0;
  background: #f6f7f8;
  box-shadow: 0px 0px 5px 2px rgb(203 208 230 / 41%);
  min-height: 430px;
}

.Mobile.MekomotSherutIndex .SearchUpCont .inputs4 .sendBlueBtn {
  width: 100%;
}

.Mobile.MekomotSherutIndex .selectIco::after {
  bottom: 13px;
}

.Mobile.MekomotSherutIndex .SearchResults .ShowDataInRow header {
  text-align: right;
}

.Mobile.MekomotSherutIndex .searchRow .tableSearchRow .coltableSearch.picCol {
  width: 30%;
}

.Mobile.MekomotSherutIndex
  .searchRow
  .tableSearchRow
  .coltableSearch.picCol
  .carouselGallery
  button:first-child {
  width: 100%;
  height: 70px;
  margin-bottom: 5px;
}

.Mobile.MekomotSherutIndex
  .searchRow
  .tableSearchRow
  .coltableSearch.picCol
  .carouselGallery
  button {
  width: 45%;
  margin-bottom: 5px;
}

.modalPictures .mobileBody .carousel {
  width: 100%;
}

.modalPictures .mobileBody .carousel .carousel-item img {
  height: 270px;
}

.Mobile.MekomotSherutIndex .ShowDataInRow header h2 {
  font-size: 17px;
}

.Mobile .sherutPlaceButtons {
  text-align: center;
  padding: 0 0 5px 0;
}

.Mobile .sherutPlaceButtons button img {
  width: 15px;
  margin: 0 0 0 5px;
}

.Mobile .sherutPlaceButtons button {
  width: 44%;
  font-size: 15px;
  text-align: center;
}

.Mobile .sherutPlaceButtons button:first-child {
  margin-left: 2%;
}

.Mobile.MekomotSherutIndex .showMoreData.mData {
  padding: 0 5% 20px;
}

.Mobile.MekomotSherutIndex .showFilters {
  width: 100%;
  margin: 15px 0 0 0;
  padding-top: 20px;
}

.Mobile.MekomotSherutIndex .SearchResults {
  margin: 20px 0 0 0;
  padding: 0 1.5%;
}

.Mobile .BtnsCol {
  padding: 15px 0 0 0;
}

.Mobile
  .SherutPlacesIndex
  .SearchCategoty
  .changePage
  .buttonsCont
  button:nth-child(2)
  img {
  width: 20px;
}

.Mobile .SherutPlacesIndex .SearchCategoty {
  margin: 0 0 20px 0;
}

.Mobile .searchMobileOpenClose {
  text-align: center;
}

.Mobile .openCloseSearchBtn {
  width: 170px;
  background: #d9e0ec;
  margin: 0 0 10px 0;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 15px;
}

.Mobile .searchMobileOpenClose {
  position: fixed;
  top: 0;
  width: 100%;
  background: #1991d0fa;
  padding: 5px 0 0 0;

  box-shadow: 0px 5px 2px rgb(203 208 230 / 41%);
}

.Mobile .searchMobileOpenClose .closeMe {
  background: white;
  color: #1f93d1;
  width: 30px;
  height: 30px;
  padding: 4px 0 0 0;
  display: inline-block;
  border-radius: 50%;
  margin: 5px 0 0 0;
  font-weight: bold;
}

.Mobile .searchMobileCont {
  text-align: right;
  padding: 0 2.5%;
}

.Mobile .openCloseSearchBtn.xBtn {
  background: transparent;
}

.Mobile.MekomotSherutIndex .line.inputs2 .inputsWithLabel:first-child {
  margin-left: 2%;
}

.Mobile.MekomotSherutIndex .line.inputs2 .inputsWithLabel {
  width: 49%;
  display: inline-block;
  margin: 2px 0;
}
.Mobile.MekomotSherutIndex .line.inputs2 .inputsWithLabel select {
  width: 100%;
  padding-left: 40px;
}

.Mobile.MekomotSherutIndex .SearchAndResults button {
  width: 100%;
  margin: 10px 0 0 0;
}

.Mobile .mobileSearchBtnFloat {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: none;
}

.header-scroll .Mobile .mobileSearchBtnFloat {
  display: block;
  text-align: center;
  z-index: 10;
}

.Mobile .showMoreData ul.iconsLine li:last-child {
  padding-right: 0;
}

.Mobile ul.iconsLine li {
  border-left: 0;
  display: block;
}

.Mobile .showMoreData {
  padding: 10px 5% 10px 0;
}

.mobileMenu .credit {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.mobileMenu .credit .WaveProjectC img {
  width: 120px;
}

.Mobile.UserConsole h1 {
  text-align: center;
  font-size: 25px;
  padding: 10px 10% 20px;
  margin: 30px 0 0 0;
}

.Mobile .messaggePopUp {
  padding: 0;
}

.Mobile .iconAfter::after {
  bottom: 15px;
}

.Mobile .multiSelect.rmsc .dropdown-heading svg {
  color: black;
}

.Mobile.MekomotSherutIndex .multiSelect.rmsc button {
  display: none;
}

.Mobile .SearchUpCont .btnClear {
  display: block;
  position: relative;
  margin: 0 0 0 0 !important;
  text-decoration: underline;
}

.Mobile .bannerCall {
  width: 90%;
  margin: 0 auto 20px;
  padding: 10px 0;
}

.Mobile .bannerCall a {
  display: block;
  text-decoration: none;
  color: #1991d0;
}

.Mobile .btnsUp {
  width: 100%;
  margin: 10px 0 0 0;
  padding: 0 0 0 10px;
}

.Mobile .btnsUp a {
  font-size: 16px;
}

.Mobile .lineTZPhone .inputTypenumber {
  width: 100% !important;
  display: block;
}

.Mobile .lineTZPhone .phoneInputs {
  width: 100% !important;
  display: block;
}

.Mobile .firstRegister .inputs .rows3 .phoneInputs .input:nth-child(2) {
  width: 60%;
}

.Mobile .firstRegister .inputs .rows3 .phoneInputs .input:nth-child(1) {
  width: 30%;
}

.Mobile .firstRegister .inputs .rows3 .phoneInputs .customSelectInput {
  width: 100%;
}

.Mobile.clockInOutIndexPClass .mobileOpenBtn {

    margin-top: 24% !important;

}

.Mobile .mobileAvatarPicture {

  text-align: center;
  margin: 0 0 -50px 0;

}

.Mobile .mobileAvatarPicture img {

  width: 30%;

}
